<template>
    <div id="content" class="col">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="row my-3">
            <div class="col-md-6">
                <div class="col-md-12">
                    <h3>{{ $t('claims.import.header') }}</h3>
                    <FileUpload class="col-md-8 my-3"
                        ref="excelFile"
                        @added="uploadFile"
                        @removed="removeFile"
                    />
                </div>
                <div class="col-md-12">
                <h4 class="pt-3" v-show="excelFile">{{ $t('claims.import.number-of-claims') }}: {{ numberOfClaims }}</h4>
                <table class="table w-75" v-show="excelFile">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{{ $t('claims.add.recipient') }}</th>
                            <th scope="col" class="text-nowrap">{{ $t('claims.import.amount_owed') }}</th>
                            <th scope="col" class="text-nowrap">{{ $t('claims.import.notification') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(i, index) in preparedClaims" v-show="index < 5">
                            <td scope="row">{{ index + 1 }}</td>
                            <td v-for="(value, iIndex) in i" :class="{ 'to-blur': iIndex === 0 }"  class="text-nowrap">
                                {{ value }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!--<div class="form-check" v-show="excelFile">
                    <input class="form-check-input" type="checkbox" value="" id="checkbox" v-model="sendNow">
                    <label class="form-check-label" for="checkbox">
                        {{ $t('claims.import.send-now') }}
                    </label>
                </div>-->
                <h4 class="mt-3" v-show="errorMsg=='WARN'"><span class="badge bg-warning">{{ $t('claims.import.check-cells') }}</span></h4>
                <h4 class="mt-3" v-show="errorMsg=='ERROR'"><span class="badge bg-danger">{{ $t('claims.import.check-cells') }}</span></h4>
                <h4 class="mt-3" v-show="errorMsg=='WRONG_TYPE'"><span class="badge bg-danger">{{ $t('claims.import.wrong-type') }}</span></h4>
                <table class="table table-borderless" v-show="errorMsg=='WARN'||errorMsg=='ERROR'">
                    <thead>
                        <tr>
                            <th>{{ $t('claims.import.name') }}</th>
                            <th>{{ $t('claims.import.cells') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="d in data">
                            <td>{{ d.username }}</td>
                            <td>{{ d.errCells.join(', ') }}</td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn btn-primary mt-4 d-flex align-items-center" :disabled="!excelFile||loading||errorMsg!=''" :class="{ 'd-none': !excelFile||errorMsg, 'loading': loading }" @click="uploadSign">{{ $t('claim.btn-import') }}</button>
            </div>
            </div>
            <div class="col-md-5">
                <ImportTemplates />
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-6">
                
            </div>
            <div class="col-md-5" style="max-height: 25%;">
                <h4 class="pt-3">{{ $t('claims.import.your-imports') }} ({{ imports.length }})</h4>
                <div class="card my-3" v-if="imports.length==0">
                    <div class="card-body d-flex justify-content-center align-items-center">
                        <p class="text-secondary my-3">Список пуст</p>
                    </div>
                </div>
                <ul class="imports-list list-group my-3">
                    <li class="list-group-item" v-for="i in imports">
                        <button class="btn btn-link" @click="openImport(i.id)">{{ i.title }}</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <ConfirmSendModal @confirmed="confirmed" />
    <ImportTitle @titleAdded="titleAdded" />
</template>

<script>
    import FileUpload from '../form/FileUpload.vue'
    import ConfirmSendModal from './ConfirmSendModal.vue'
    import { rates } from '../../../libs/rate'
    import ImportTitle from './ImportTitle.vue'
    import ImportTemplates from './ImportTemplates.vue'

    export default {
        data: () => ({
            excelFile: '',
            filename: '',
            claims: [],
            preparedClaims: [],
            numberOfClaims: 0,
            sendNow: false,
            errorMsg: '',
            data: [],
            currentPlan: {},
            loading: false,
            importTitle: '',
            imports: []
        }),
        components: {
            FileUpload,
            ConfirmSendModal,
            ImportTitle,
            ImportTemplates
        },
        computed: {
            isBlurred: {
                get() {
                    return this.$store.getters.getBlur == true
                },
                set(value) {
                    this.$store.commit('setBlur', value)
                },
            },
        },
        watch: {
            preparedClaims: {
                handler(newValue, oldValue) {
                    this.applyBlurState()
                },
                immediate: true, 
            },
        },
        beforeDestroy() {
            window.removeEventListener('storage', this.syncBlurState)
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', this.$t('sidebar.import-claims'))

            this.$refs.excelFile.files = []
            this.getCurrentPlan()
            this.getImports()
            window.addEventListener('storage', this.syncBlurState)
        },
        methods: {
            syncBlurState(event) {
                if (event.key === 'isBlurred') {
                    
                    this.isBlurred = event.newValue === 'true'
                }
            },
            applyBlurState() {
                // Add or remove the 'blurred' class based on the current blur state
                document.querySelectorAll('.to-blur').forEach(element => {
                    if (this.isBlurred) {
                        element.classList.add('blurred')
                        this.$store.commit('setBlur', true)
                    } else {
                        element.classList.remove('blurred')
                        this.$store.commit('setBlur', false)
                    }
                })
            },
            langChange() {
                this.$emit('setPageTitle', this.$t('sidebar.import-claims'))
            },
            uploadSign() {
                if(!this.excelFile) return

                if(!this.sendNow) {
                    this.loading = true
                    this.$store.commit('modalShow', { id: 'importTitle' })
                    return

                    this.uploadToDB()
                } else {
                    this.loading = true
                    
                    this.showConfirm()
                }
            },
            titleAdded(title) {
                this.uploadToDB({}, title)
            },
            async uploadToDB(data = {}, title = '') {
                let body = {
                    user_id: this.$store.state.user.id,
                    send: this.sendNow,
                    filename: this.filename,
                    import_title: title
                }

                let formData = new FormData()

                for(let i in body) {
                    formData.append(i, body[i])
                }

                for(let i in data) {
                    if(i != 'sign') {
                        formData.append(i, data[i])
                    } else {
                        formData.append('file', data[i], data[i].name)
                    }
                }

                let url = process.env.VUE_APP_API_URL + '/claims/upload'

                await axios.post(url, formData, {
                    withCredentials: true
                }).then(async (response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: 'SUCCESS',
                            text: r.message,
                            type: 'success'
                        })

                        this.$emit('updateCounters')
                        
                        this.$router.push({ path: '/saved-import', query: { id: r.data }})
                    } else if(r.result === 'WARN') {
                        this.data = r.data

                        this.$notify({
                            title: 'WARN',
                            text: r.message,
                            type: 'warn'
                        })

                        this.errorMsg = r.result
                        for(let e in r.data) {
                            for(let m in r.msgs[e]) {
                                r.data[e].errCells[m] += ' - ' + r.msgs[e][m]
                            }
                        }
                    } else if(r.result === 'ERROR') {
                        this.data = r.data
                        
                        this.$notify({
                            title: 'ERROR',
                            text: r.message,
                            type: 'error'
                        })
                        
                        if(r.code == 'low balance') return
                        
                        this.errorMsg = r.result

                        if(!r.data) return

                        for(let e in r.data) {
                            for(let m in r.msgs[e]) {
                                r.data[e].errCells[m] += ' - ' + r.msgs[e][m]
                            }
                        }
                    }
                })
                this.loading = false
            },
            async uploadFile(files) {
                if(!files[0].type.includes('ms-excel') && !files[0].type.includes('spreadsheet')) {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: 'Неправильный формат',
                        type: 'error'
                    })
                    return
                }
                
                let uploadUrl = process.env.VUE_APP_API_URL + '/files/upload'

                let formData = new FormData()
                formData.append("file", files[0], files[0].name)

                let attachment = await axios.post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true
                }).then((response) => {
                    return response.data
                }).catch(function (err) {
                    if(process.env.VUE_APP_DEBUG) console.log(err)
                })

                if(attachment.data) {
                    this.$refs.excelFile.files.push({
                        id: attachment.data,
                        name: attachment.originalname
                    })
                }

                this.excelFile = attachment.data ?? ''
                this.filename = attachment.filename

                if(this.filename) {
                    await this.import()
                }

                this.applyBlurState()
            },
            async import() {
                this.loading = true
                let url = process.env.VUE_APP_API_URL + '/claims/import'

                await axios.get(url, {
                    params: {
                        filename: this.filename
                    },
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'WARN') {
                        this.data = r.resData

                        this.$notify({
                            title: 'WARN',
                            text: r.message,
                            type: 'warn'
                        })

                        this.errorMsg = r.result
                        for(let e in r.resData) {
                            for(let m in r.msgs[e]) {
                                r.resData[e].errCells[m] += ' - ' + r.msgs[e][m]
                            }
                        }
                    } else if(r.result === 'ERROR') {
                        this.data = r.resData
                        
                        this.$notify({
                            title: 'ERROR',
                            text: r.message,
                            type: 'error'
                        })
                        
                        if(r.code == 'low balance') return
                        
                        this.errorMsg = r.result

                        if(!r.resData) return

                        for(let e in r.resData) {
                            for(let m in r.msgs[e]) {
                                r.resData[e].errCells[m] += ' - ' + r.msgs[e][m]
                            }
                        }
                    } else if(r.result === 'WRONG_TYPE') {
                        this.$notify({
                            title: 'ERROR',
                            text: r.message[0],
                            type: 'error'
                        })

                        this.errorMsg = 'WRONG_TYPE'
                    }

                    this.claims = r.data

                    this.numberOfClaims = r.data.length
                    
                    this.prepareClaims()
                })
                this.loading = false
            },
            async removeFile(fileId) {
                let url = process.env.VUE_APP_API_URL + `/files/${fileId}/remove`
                
                let removed = await axios.get(url)
                .then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log(response)

                    let r = response.data

                    return r.result === 'SUCCESS'
                })

                if(removed) {
                    // this.attachments = this.attachments.filter(file => file != fileId)
                    // this.attachmentsData = this.attachmentsData.filter(attach => attach.id != fileId)
                    // this.$refs.file.files = this.attachmentsData

                    this.excelFile = ''
                    this.filename = ''
                    this.$refs.excelFile.files = []
                    this.$refs.excelFile.clear()
                    this.claims = []
                    this.preparedClaims = []
                    this.numberOfClaims = 0
                    this.errorMsg = ''
                    this.data = []

                    if(process.env.VUE_APP_DEBUG) console.log('excelFile', this.$refs.excelFile.files)

                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: 'Файл удален',
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: 'Не удалось удалить файл',
                        type: 'error'
                    })
                }
            },
            prepareClaims() {
                for(let i in this.claims) {
                    let preparedClaim = []

                    if(!this.claims[i].length) continue
                    if(process.env.VUE_APP_DEBUG) console.log(this.claims[i])
                    
                    preparedClaim.push(this.claims[i][4] ?? this.claims[i][1] + ' ' + this.claims[i][2])
                    preparedClaim.push(this.claims[i][14].toLocaleString('kk-KZ'))
                    preparedClaim.push(this.claims[i][12])

                    this.preparedClaims.push(preparedClaim)
                }
            },
            async getCurrentPlan() {
                let url = process.env.VUE_APP_API_URL + '/rates/get-current-plan'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.currentPlan = r.data
                        if(this.currentPlan.id < rates.enterprise) {
                            this.$router.push('/settings/rates')
                        }
                    }
                })
            },
            async getImports() {
                let url = process.env.VUE_APP_API_URL + '/claims/get-imports'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.imports = r.data
                    }
                })
            },
            openImport(id) {
                this.$router.push({ path: '/saved-import', query: { id: id } })
            },
            showConfirm() {
                this.$store.commit('modalShow', { id: 'confirmSendModal' })
            },
            confirmed(data) {
                this.loading = true

                this.uploadToDB(data)
            }
        }
    }

</script>

<style scoped>

.btn-link {
    display: flex;
    align-items: center;
    padding: .5rem .5rem;
    color: #8f82d8;
    background: none !important;
    text-decoration: none !important;
    font-size: 1rem;
    font-weight: 400;
    text-transform: none;
    outline: none !important;
}

.btn-link:hover {
    color: #5a4ac2;
    outline: none !important;
}
.btn-link:focus {
    /* outline: 2px solid #a6a0fb !important; */
    color: #5a4ac2;
}

.btn-link:active {
    color: #5a4ac2 !important;
    background: none !important;
    transform: translate(2px, 2px);
    outline: none !important;
}

.imports-list {
    max-height: 250px;
    overflow-y: auto;
}

</style>