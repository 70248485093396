<template>
    <div class="card">
        <div class="card-body">
            <p class="text-end mb-3">
                {{ $t('claim-text.recipient-username', this.language) }}:  <span class="to-blur">{{recipientUsername}}</span> <br/>
                {{ $t('claim-text.recipient-address', this.language) }}: <span class="to-blur">{{ recipientAddress }}</span><br/>
                {{ $t('claim-text.sender-username', this.language) }}: <span class="to-blur">{{ senderUsername }}</span><br/>
                {{ $t('claim-text.sender-address', this.language) }}: <span class="to-blur">{{ senderAddress }}</span>
            </p>
            <h5 class="mb-4 text-center">{{ $t('claim-text.title', this.language) }}</h5>
            <div v-show="false">{{ claim }}</div>
            <div class="template-body-text">
                <component
                    ref="template"
                    v-if="!text"
                    :is="'Template' + claim.template"
                    :claim="claim"
                    :preview="preview"
                    @templateFields="templateFields"
                    @setLanguage="setLanguage"
                ></component>
                <div v-else v-html="processedText"></div>
            </div>
            
            <p style="margin: 30px 0 60px;">
                <b><span class="to-blur">{{ senderUsername }}</span></b>
                <span class="d-block to-blur" v-if="representative">{{ representative }}</span>
            </p>
        </div>
    </div>
</template>

<script>
    import Templatedogovor from './TemplateDogovor.vue'
    import Templatezp from './TemplateZP.vue'
    import Templatevozvrat from './TemplateVozvrat.vue'
    import Templateosi from './TemplateOsi.vue'
    import Templatemfo from './TemplateMFO.vue'
    import Templatemfogarant from './TemplateMFOGarant.vue'
    import Templatemforazbivka from './TemplateMFORazbivka.vue'
    import Templatezadatok from './TemplateZadatok.vue'
    import Templatevodokanal from './TemplateVodokanal.vue'
    import Templatefreeform from './TemplateFreeform.vue'
    import { shortenCompanyName } from '../../../../libs/text'

    export default {
        data: () => ({
            dueDate: 10,
            textEdited: '',
            language: 'ru'
        }),
        props: {
            claim: {
                type: Object,
                default: {}
            },
            preview: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: ''
            },
            realClaim: {
                type: Object,
                default: {}
            }
        },
        components: {
            Templatedogovor,
            Templatezp,
            Templatevozvrat,
            Templateosi,
            Templatemfo,
            Templatemfogarant,
            Templatemforazbivka,
            Templatezadatok,
            Templatevodokanal,
            Templatefreeform
        },
        watch: {
            claim: {
                immediate: true,
                handler(value) {
                    if(value.essenceLanguage)
                        this.language = value.essenceLanguage
                }
            },
            realClaim: {
                immediate: true,
                handler(value) {
                    if(value.essenceLanguage)
                        this.language = value.essenceLanguage
                }
            },
            'claim.text': {
                handler(newValue, oldValue) {
                    this.applyBlurState()
                },
                immediate: true, 
            },
            'claim.template': {
                handler(newValue, oldValue) {
                    this.applyBlurState()
                },
                immediate: true, 
            },
            'claim.template_fields': {
                handler(newValue, oldValue) {
                    this.applyBlurState()
                },
                immediate: true, 
            },
        },
        computed: {
            processedText() {
                // Create a DOM parser to manipulate the text
                const parser = new DOMParser()
                const doc = parser.parseFromString(this.text, "text/html")

                // Find all elements with the `to-blur` class
                const elements = doc.querySelectorAll(".to-blur")

                // Add the `blurred` class if `isBlurred` is true
                if (this.isBlurred) {
                    elements.forEach((el) => {
                    el.classList.add("blurred")
                    })
                }

                // Return the modified HTML as a string
                return doc.body.innerHTML
            },
            content: {
                get() {
                    if(process.env.VUE_APP_DEBUG) console.log('content', this.claim)
                    return this.claim.content || '123'
                }
            },
            recipientUsername: {
                get() {
                    if(this.claim.recipient) {
                        if(this.claim.recipient.company_name) {
                            return shortenCompanyName(this.claim.recipient.company_name)
                        }
                    }
                    if(!this.claim.recipient || !this.claim.recipient.title) return this.$t('claim-text.default-recipient-username')
                    return this.claim.recipient.title
                }
            },
            recipientAddress: {
                get() {
                    if(!this.claim.recipient || !this.claim.recipient.address) return this.$t('claim-text.default-recipient-address')
                    return this.claim.recipient.address
                }
            },
            senderUsername: {
                get() {
                    if(!this.claim.sender || !this.claim.sender.fullName) return this.$t('claim-text.default-sender-username')
                    if(this.claim.sender.company_name) {
                        return shortenCompanyName(this.claim.sender.company_name)
                    }
                    return this.claim.sender.fullName
                }
            },
            representative: {
                get() {
                    if(!this.claim.sender || !this.claim.sender.company_name) return ''
                    return this.claim.sender.fullName
                }
            },
            senderIdNum: {
                get() {
                    if(!this.claim.sender || !this.claim.sender.id_no) return this.$t('claim-text.default-sender-id_no')
                    if(this.claim.sender.company_id_num) return this.claim.sender.company_id_num
                    return this.claim.sender.id_no
                }
            },
            senderAddress: {
                get() {
                    if(!this.claim.sender || !this.claim.sender.address) return this.$t('claim-text.default-sender-address')
                    return this.claim.sender.address
                }
            },
            isBlurred: {
                get() {
                    return this.$store.getters.getBlur == true
                },
                set(value) {
                    this.$store.commit('setBlur', value)
                },
            },
        },
        mounted() {
            window.addEventListener('storage', this.syncBlurState)
        },
        beforeDestroy() {
            window.removeEventListener('storage', this.syncBlurState)
        },
        methods: {
            syncBlurState(event) {
                if (event.key === 'isBlurred') {
                    
                    this.isBlurred = event.newValue === 'true'
                }
            },
            applyBlurState() {
                // Add or remove the 'blurred' class based on the current blur state
                document.querySelectorAll('.to-blur').forEach(element => {
                    if (this.isBlurred) {
                        element.classList.add('blurred')
                        this.$store.commit('setBlur', true)
                    } else {
                        element.classList.remove('blurred')
                        this.$store.commit('setBlur', false)
                    }
                })
            },
            getTemplate() {
                let defaultTemplate = ''

                return this.pasteValues(this.pastePlaceholders(this.claim.template), this.claim) ?? defaultTemplate
            },
            templateFields(fields) {
                this.$emit('templateFields', fields)
            },
            updateTemplateFields(fields) {
                this.$refs.template.updateTemplateFields(fields)
            },
            pasteValues(text, tags = {}) {
                if(process.env.VUE_APP_DEBUG) console.log('tags', tags)
                for(let i in tags) {
                    text = text.replace(`_${i}_`, `${tags[i]}`)
                }

                return text
            },
            pastePlaceholders(text) {
                const placeholders = {
                    recipientUsername: this.$t('claim-text.default-recipient-username'),
                    recipientAddress: this.$t('claim-text.default-recipient-address'),
                    senderUsername: this.$t('claim-text.default-sender-username'),
                    senderIdNum: this.$t('claim-text.default-sender-id_no'),
                    senderAddress: this.$t('claim-text.default-sender-address')
                }

                for(let i in placeholders) {
                    text = text.replace(`_${i}_`, `${placeholders[i] ?? i}`)
                }

                return text
            },
            getTemplateText() {
                let text = this.pastePlaceholders(this.getTemplate())

                if(!this.claim) return text

                return this.pasteValues(text, this.claim)
            },
            setLanguage(language) {
                this.language = language
            }
        },
        updated() {
            this.applyBlurState() // Reapply the blur state after component updates (e.g., navigation)
        }
    }
</script>

<style scoped>

@media (min-width: 1024px) {
    .card-body {
        padding: 3em 4em;
    }
}

</style>