<template>
    <Form class="p-3 rounded mx-2" ref="form" @submit="onSubmit">
        <div class="row mb-3 d-flex align-items-bottom">
            <div class="col-md-4">
                <Input
                    v-model="id_no"
                    name="id_no"
                    maska="############"
                    :placeholder="$t('recipients.form.id_no')"
                    :validations="['required', 'minLength(12)', 'maxLength(12)']"
                    v-if="searchType==='iin'"
                    :toBlur="true"
                />
                <Input
                    v-model="phone"
                    name="phone"
                    maska="+7 (###) ###-##-##"
                    :placeholder="$t('recipients.form.phone')"
                    :validations="isFound ? ['maxLength(20)'] : ['required', 'minLength(12)', 'maxLength(20)']"
                    v-if="searchType==='phone'"
                    :toBlur="true"
                />
                <Input
                    v-model="bin"
                    name="bin"
                    maska="############"
                    :placeholder="$t('recipients.form.bin')"
                    :validations="['required', 'minLength(12)', 'maxLength(12)']"
                    v-if="searchType==='bin'"
                    :toBlur="true"
                />
            </div>
            <div class="col-md-4" v-if="searchType==='iin'||searchType==='phone'">
                <Input
                    v-model="lastName"
                    name="lastName"
                    maska="A"
                    maska-tokens="A:[а-яА-ЯёЁa-zA-Z\u04D8\u04D9\u04B0\u04B1\u0406\u0456\u04A2\u04A3\u0492\u0493\u04AE\u04AF\u049A\u049B\u04E8\u04E9\u04BA\u04BB]:multiple"
                    :placeholder="$t('recipients.form.lastName')"
                    :validations="['required', 'minLength(3)', 'maxLength(50)']"
                    :toBlur="true"
                />
            </div>
            <div class="col-md-4 d-flex justify-content-center align-items-start">
                <button type="submit" class="btn btn-primary mx-0">{{ $t('recipients.form.search') }}</button>
            </div>
        </div>
        <div class="row mb-3" v-if="errorMsg">
            <span class="text-danger">{{ errorMsg }}</span>
        </div>
        <div class="row mb-3" v-if="showDataList&&!errorMsg">
            <DataList
                ref="dl"
                :columns="columns"
                :data="recipients"
                :enableSearch="false"
                @row-clicked="recipientAdded"
                :toBlur="true"
            />
        </div>
        <button type="button" class="btn btn-primary" v-if="!isFound&&showCreateBtn" @click="openAddRecipient">{{ $t('recipients.form.create-recipient') }}</button>
    </Form>
    <button type="button" class="d-none" data-bs-dismiss="modal" ref="recipientSearchModalClose"></button>
</template>

<script>

    import DataList from '../cabinet/DataList.vue'
    import { Form } from 'vee-validate'
    import Input from '../form/Input.vue'

    export default {
        data: () => ({
            recipient: {},
            recipients: [],
            id: '',
            firstName: '',
            lastName: '',
            patronymic: '',
            id_no: '',
            bin: '',
            phone: '',
            isFound: false,
            showSearchFields: true,
            errorMsg: '',
            showResetBtn: false,
            showDeleteBtn: false,
            showDataList: false,
            showCreateBtn: false,
            currentPage: 1,
            perPage: 3
        }),
        props: {
            searchType: {
                type: String,
                default: 'iin'
            }
        },
        components: {
            DataList,
            Form,
            Input
        },
        computed: {
            columns: {
                get() {
                    let cols = []

                    if(this.searchType === 'iin') {
                        cols.push({ value: 'id_no', text: 'ИИН', sortable: true })
                        cols.push({ value: 'lastName', text: 'Фамилия', sortable: true })
                    } else if(this.searchType === 'phone') {
                        cols.push({ value: 'phone', text: 'Телефон', sortable: true })
                        cols.push({ value: 'lastName', text: 'Фамилия', sortable: true })
                    } else {
                        cols.push({ value: 'company_id_num', text: 'БИН', sortable: true })
                        cols.push({ value: `${this.recipient.company_name ? 'company_name' : 'title' }`, text: 'Наименование', sortable: true })
                    }

                    return cols
                }
            }
        },
        methods: {
            onSubmit() {
                this.search()
            },
            async getByFields(fields, values) {
                let url = process.env.VUE_APP_API_URL + '/recipients/by-fields'

                let q = await axios.post(url, {                    
                        fields: fields,
                        values: values, 
                        currentUserId: this.$store.state.user.id
                    }, { 
                        withCredentials: true
                    }).then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        return r.data
                    } else {
                        return false
                    }

                })

                return q
            },
            async search() { // search button action
                let recipient = false
                this.errorMsg = ''
                this.showCreateBtn = false
                this.recipients = []

                if(this.searchType === 'iin') {
                    if(this.id_no.length == 12) {
                        if(this.$store.state.user.settings.iinBin == this.id_no) { // check if own id
                            this.errorMsg = this.$t('recipients.form.own-id-num')
                            this.showSearchFields = true

                            if(process.env.VUE_APP_DEBUG) console.log('own id')
                        } else if(this.$libs.user.checkIdNum(this.id_no)) { // check id for validity
                            if(this.$libs.user.extractFromIdNum(this.id_no, 'type') === 'person') { // check if person
                                recipient = await this.getByFields(['id_no', 'lastName', 'company_id_num'], [this.id_no, this.lastName, ''])
                                // recipient = await this.getBy('id_no', this.id_no)

                                if(recipient) { // if recipient in the system
                                    recipient.class = 'row-regular'

                                    this.recipient = recipient
                                    this.recipients.push(recipient)
                                    this.isFound = true
                                    this.showDataList = true
                                } else { // if recipient NOT in the system
                                    this.recipients = []
                                    this.isFound = false
                                    this.showCreateBtn = true
                                    this.showDataList = true
                                }
                            } else {
                                if(process.env.VUE_APP_DEBUG) console.log('Not a person')

                                this.errorMsg = this.$t('recipients.form.error')
                                this.errorMsg = 'Wrong id'
                                this.showSearchFields = true
                            }
                        } else {
                            if(process.env.VUE_APP_DEBUG) console.log('Incorrect id')

                            this.errorMsg = this.$t('recipients.form.error')
                            this.errorMsg = 'Wrong id'
                            this.showSearchFields = true
                        }
                    }
                } else if(this.searchType === 'phone') {
                    if(this.$libs.user.isPhoneNumber(this.phone)) { // check phone for validity
                        recipient = await this.getByFields(['phone', 'lastName', 'company_id_num'], [this.phone, this.lastName, ''])

                        if(recipient) { // if recipient in the system
                            recipient.class = 'row-regular'

                            this.recipient = recipient
                            this.recipients.push(recipient)
                            this.isFound = true
                            this.showDataList = true
                        } else { // if recipient NOT in the system
                            this.recipients = []
                            this.isFound = false
                            this.showCreateBtn = true
                            this.showDataList = true
                        }
                    } else {
                        if(process.env.VUE_APP_DEBUG) console.log('Incorrect phone')

                        this.errorMsg = this.$t('recipients.form.error')
                        this.errorMsg = 'Wrong id'
                        this.showSearchFields = true
                    }
                } else if(this.searchType === 'bin') {
                    if(this.bin.length == 12) {
                        if(this.$store.state.user.settings.company_id_num == this.bin) { // check if own id
                            this.errorMsg = this.$t('recipients.form.own-id-num')
                            this.showSearchFields = true

                            if(process.env.VUE_APP_DEBUG) console.log('own id')
                        } else if(this.$libs.user.checkIdNum(this.bin)) { // check id for validity
                            if(this.$libs.user.extractFromIdNum(this.bin, 'type') === 'entity') { // check if company
                                recipient = await this.getByFields(['company_id_num'], [this.bin])

                                if(process.env.VUE_APP_DEBUG) console.log('bin recipient', recipient)

                                if(recipient) { // if recipient in the system
                                    recipient.class = 'row-regular'

                                    this.recipient = recipient
                                    this.recipients.push(recipient)
                                    this.isFound = true
                                    this.showDataList = true
                                } else { // if recipient NOT in the system
                                    this.recipients = []
                                    this.isFound = false
                                    this.showCreateBtn = true
                                    this.showDataList = true
                                }
                            } else {
                                if(process.env.VUE_APP_DEBUG) console.log('Not a company')

                                this.errorMsg = this.$t('recipients.form.error')
                                this.errorMsg = 'Wrong id'
                                this.showSearchFields = true
                            }
                        } else {
                            if(process.env.VUE_APP_DEBUG) console.log('Incorrect id')

                            this.errorMsg = this.$t('recipients.form.error')
                            this.errorMsg = 'Wrong id'
                            this.showSearchFields = true
                        }
                    }
                }

                // this.errorMsg = ''
                // this.showSearchFields = false
            },
            recipientAdded() {
                this.$emit('recipientAdded', this.recipient.id)
            },
            openAddRecipient() {
                if(this.searchType === 'iin') this.$emit('openAddRecipientModal', this.searchType, this.id_no, this.lastName)
                if(this.searchType === 'phone') this.$emit('openAddRecipientModal', this.searchType, this.phone, this.lastName)
                if(this.searchType === 'bin') this.$emit('openAddRecipientModal', this.searchType, this.bin)
            },
            reset() {
                this.$refs.form.resetForm() // reset input errors

                this.recipient = {}
                this.recipients = []
                this.showDataList = false
                this.showCreateBtn = false
                this.errorMsg = ''
            }
        }
    }

</script>
